.table {
  text-align: center;
  width: auto;
  margin: auto;
  left: 0;
  right: 0;

  tr,
  td {
    border: 1px solid black;
    border-spacing: 2px;
    padding: 2px;
    @media (max-width: 600px) {
      font-size: 0.7rem !important;
    }
    @media (max-width: 480px) {
      font-size: 0.5rem !important;
    }
  }
}