.filter {
  display: grid;
  //grid-template-columns: 0.4fr repeat(2, 1.5fr) repeat(3, 0.8fr);
  grid-template-columns: 0.2fr repeat(3, 2fr) 0.8fr;
  color: #fff;
  font-weight: bold;
  padding-bottom: 20px;
}

.buttonDown, .buttonUp {
  position: relative;
  background-color: #a4c11da8;
  font-family: "Lato", sans-serif;
}

.buttonDown:after {
  bottom: -20px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #158a4b;
  border-width: 8px;
  margin-left: -8px;
}

.buttonUp:after {
  bottom: -12px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ed1f33;
  border-width: 8px;
  margin-left: -8px;
}

.button {
  cursor: pointer;
  background-color: #36720E;
  font-family: "Lato", sans-serif;
  outline-color: #72c15c;
  line-height: 1.6em
}

.white {
  color: var(--ffffff);
}

.buttonActive {
  background-color: var(--q8da356);
}

.button:hover {
  background-color: #7EA565;
}

.button:active {
  background-color: #a4c11da8;
}

.button {
  background-color: #36720E;
}