.content {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--q00000);
    @media (min-width: 992px) {
        &> div:first-child {
            float: left;
        }
    }
}