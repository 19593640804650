.list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 1199px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 719px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 499px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
  }
}