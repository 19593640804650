@import "../../node_modules/normalize.css/normalize.css";
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic|Montserrat:700|Merriweather:400italic');

:root {
	--ffffff: #ffffff;
	--fbfbf9: #fbfbf9;
	--eeeeee: #eeeeee;
	--eeede9: #eeede9;
	--f9f9f9: #f9f9f9;
	--cccccc: #cccccc;
	--e5e5e5: #e5e5e5;
	--f5f5f5: #f5f5f5;
	--e8e8e8: #e8e8e8;
	--fcf8e3: #fcf8e3;
	--faf2cc: #faf2cc;
	--none: none;
	--ee9900: #ee9900;
	--transparent: transparent;
	--ed1f33: #ed1f33;
	--D32681: #D32681;
	--d74980: #d74980;
	--q00000: #000000;
	--q262626: #262626;
	--q33333: #333333;
	--q44444: #444444;
	--q55555: #555555;
	--q66666: #666666;
	--q767676: #767676;
	--q999: #999999;
	--q2b542c: #542b31;
	--q158a4b: #158a4b;
	--q36720E: #36720E;
	--q5E8E3E: #5e8e3e;
	--q72c15c: #72c15c;
	--q7EA565: #7EA565;
	--q8da356: #8da356;
	--a4c11da8: #a4c11da8;
	--q8ba709: #8ba709;
	--qa4c11d: #a4c11d;
	--dff0d8: #dff0d8;
	--d0e9c6: #d0e9c6;
	--q122b40: #122b40;
	--q204d74: #204d74;
	--q286090: #286090;
	--q2e6da4: #2e6da4;
	--q337ab7: #337ab7;
	--q3873ae: #3873ae;
	--q4893ce: #4893ce;
	--q689cc5: #689cc5;
	--q51a6d3: #51a6d3;
	--q66afe9: #66afe9;
	--q62c6f8: #62c6f8;
	--q82e6ff: #82e6ff;
	--q3bbec0: #3bbec0;
	--q93dbe9: #93dbe9;
	--d9edf7: #d9edf7;
	--c4e3f3: #c4e3f3;
}

html {
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: calc(100% - 100vw);
}
body {
	width: 100%;
	height: 100vh;
	font-weight: 300;
	background-color: #fff;
	overflow-x: hidden;
	font-family: "Montserrat",sans-serif;
	color: #666;
}

a {
	text-decoration: none;
	color: black;
}

/*a,.tp-caption a.btn:hover {
	color: #36720E;
}
.top-nav-collapse {
	background: #36720E;
}
@media (max-width: 767px) {
  .navbar, .navbar-nav {
		background: #36720E !important;
	}

	.navbar-nav a {
		color: #fff !important;
	}
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
	color: #fff;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    background-color: #87c10a;
}

.widget-avatar .avatar:hover {
    border: 5px solid #36720E;
}

.bg-skin {
	background: #36720E;
}

form#contact-form input:focus,form#contact-form select:focus,form#contact-form textarea:focus {
	border-color: #36720E;
}

.btn-skin {
  color: #fff;
  background-color: #36720E;
  border-color: #36720E;
}*/
