.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.doubleUp:before {
  content: '\f102';
}
.doubleDown:before {
  content: '\f103';
}
.down:before {
  content: '\f107';
}
.youtube:before {
  content: '\f167';
}
.facebook:before {
  content: '\f09a';
}
.instagram:before {
  content: '\f16d';
}
.bars:before {
  content: '\f0c9';
}
.x2 {
  font-size: 2em;
}
.doubleUp:before {
  content: '\f102';
}
.doubleDown:before {
  content: '\f103';
}
