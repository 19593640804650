.hidden {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.formGroup {
  margin-bottom: 15px;
}

.panelBody {
  padding: 15px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btnPrimary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btnPrimary:focus,
.btnPrimary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.btnPrimary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btnPrimary:active,
.btnPrimary.active,
.open > .dropdownToggle.btnPrimary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btnPrimary:active:hover,
.btnPrimary.active:hover,
.open > .dropdown-toggle.btnPrimary:hover,
.btnPrimary:active:focus,
.btnPrimary.active:focus,
.open > .dropdown-toggle.btnPrimary:focus,
.btnPrimary:active.focus,
.btnPrimary.active.focus,
.open > .dropdown-toggle.btnPrimary.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btnPrimary:active,
.btnPrimary.active,
.open > .dropdownToggle.btnPrimary {
  background-image: none;
}

.btnPrimary.disabled:hover,
.btnPrimary[disabled]:hover,
fieldset[disabled] .btnPrimary:hover,
.btnPrimary.disabled:focus,
.btnPrimary[disabled]:focus,
fieldset[disabled] .btnPrimary:focus,
.btnPrimary.disabled.focus,
.btnPrimary[disabled].focus,
fieldset[disabled] .btnPrimary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btnPrimary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btnSm,
.btnGroupSm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.formGroupFirst {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.formGroupSecond {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.formGroupThird {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}