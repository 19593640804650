form[name=echForm] {
  display: grid;
  /*grid-template-columns: 1fr 1fr 3fr;*/
}

.formControl {
  display: block;
  width: calc(100% - 12px);
  height: 34px;
  padding: 0 6px;
  margin-right: 6px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.formControl:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.formControl::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.formControl:-ms-input-placeholder {
  color: #999;
}

.formControl::-webkit-input-placeholder {
  color: #999;
}

.formControl::-ms-expand {
  background-color: transparent;
  border: 0;
}

.formControl[disabled],
.formControl[readonly],
fieldset[disabled] .formControl {
  background-color: #eee;
  opacity: 1;
}

.formControl[disabled],
fieldset[disabled] .formControl {
  cursor: not-allowed;
}

textarea.formControl {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].formControl,
  input[type="time"].formControl,
  input[type="datetime-local"].formControl,
  input[type="month"].formControl {
    line-height: 34px;
  }

  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .inputGroupSm input[type="date"],
  .inputGroupSm input[type="time"],
  .inputGroupSm input[type="datetime-local"],
  .inputGroupSm input[type="month"] {
    line-height: 30px;
  }

  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .inputGroupLg input[type="date"],
  .inputGroupLg input[type="time"],
  .inputGroupLg input[type="datetime-local"],
  .inputGroupLg input[type="month"] {
    line-height: 46px;
  }
}
