.error {
  text-align: center;
  position: relative;
  padding-top: 170px;
  padding-bottom: 170px;
  color: var(--q00000);
  &::before {
    background: url(../../img/404/404.svg) no-repeat 50%;
    width: 535px;
    height: 293px;
    content: "";
    display: block;
    left: 0;
    right: 0;
    margin: auto;
  }
}