.previous {
    position: fixed;
    background: url("../../img/arrow.png");
    background-size: contain ;
    transform: rotate(180deg);
    left: 0;
    top: 55%;
    bottom: 0;
    margin-left: 1em;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    z-index: 1;
}