.flex {
  display: flex;
  width: fit-content;
}

.mobileNone {
  @media (max-width: 767px) {
    display: grid;
  }
}

.navbar {
  /*  margin-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  @media (min-width: 767px) {
    padding: 20px 0;
    border-bottom: 0;
    letter-spacing: 1px;
    background: 0 0;
    -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    -moz-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  }*/
}

.nav {
  display: flex;
  position: relative;
  /*flex-direction: column;*/
  background-color: transparent;
  margin: 0;
  padding-left: 0;
  list-style: none;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    float: left;
  }
}

.holder > a {
  padding: 0;
  margin: 0;
  top: 0;
}

.right {
  @media (min-width: 768px) {
    float: right !important;
  }
}

.header {
  @media (min-width: 768px) {
    float: left;
  }
}

.custom li a {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  @media (max-width: 767px) {
    color: #fff;
  }
}

.fixed {
  top: 0;
  border-width: 0 0 1px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  @media (max-width: 767px) {
    color: #ffffff;
  }
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
}

.logo {
  background: url(../../img/bw.jpg) no-repeat top center;
  background-size: contain;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  @media (max-width: 767px) {
    margin-left: 17px;
  }
}

.collapse {
  color: #ffffff;
  background: #36720e;
  padding: 0;
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.collapsedLogo {
  background: url(../../img/avatar_1_black_white.png) no-repeat top center;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.ech {
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*  @media (max-width: 767px) {
      background: #36720e url(../../img/catalog_white.png) no-repeat top center;
    }*/
}

.collapsedEch {
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*  @media (max-width: 767px) {
      background: #36720e url(../../img/catalog_white.png) no-repeat top center;
    }*/
}

@media (min-width: 768px) and (max-width: 1199px) {
  .collapse.right.main-collapse {
    position: absolute;
    right: 0;
  }

  #contact .widget-contact {
    margin: 30px 0;
  }
}

.custom.top-nav-collapse .nav.nav {
  background-color: #333333;
}

.collapse ul.nav a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  @media (max-width: 767px) {
    color: #fff;
  }
}

.custom ul.nav ul.dropdown-menu {
  border-radius: 0;
}

.custom ul.nav ul.dropdown-menu li {
  border-bottom: 1px solid #f5f5f5;
}

.custom ul.nav ul.dropdown-menu li:last-child {
  border-bottom: none;
}

.custom ul.nav ul.dropdown-menu li a {
  padding: 10px 20px;
}

.custom ul.nav ul.dropdown-menu li a:hover {
  background: #a4c11d;
}

.collapse ul.nav ul.dropdown-menu li a {
  color: #666;
}

.custom .nav li a {
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.custom .nav li a:hover,
.custom .nav li a:focus,
.custom .nav li.active {
  outline: 0;
  background-color: #a4c11d;
}

.custom .nav li a:hover,
.custom .nav li a:focus,
.custom .nav li.active {
  outline: 0;
  background-color: #a4c11d;
}

.toggle {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 0;
  padding: 3px 6px;
  font-size: 30px;
  color: #36720e;
  z-index: 10;
  position: relative;
  float: right;
  margin-right: 15px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  @media (min-width: 767px) {
    display: none;
  }
  @media (max-width: 767px) {
    color: #000;
  }
}

.collapsedToggle {
  margin-top: 5px;
  margin-bottom: 0;
  padding: 3px 6px;
  font-size: 30px;
  color: #fff;
  z-index: 10;
  position: relative;
  float: right;
  margin-right: 15px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  @media (min-width: 768px) {
    display: none;
  }
}

.toggle:focus,
.toggle:active {
  outline: 0;
}

.width {
  margin: auto;
  left: 0;
  right: 0;
  max-width: 1200px;
}

.flagTop,
.flagBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  position: relative;
  width: 100%;
  color: #ffffff;
}

.flagTop {
  background-color: #0a5488;
}

.flagBottom {
  background-color: #f9cd0b;
}
