.item {
  display: grid;
  //grid-template-columns: 0.4fr repeat(2, 1.5fr) repeat(3, 0.8fr);
  grid-template-columns: 0.2fr repeat(3, 2fr) 0.8fr;
  border: 1px solid #333
}

.id,
.height,
.price {
  text-align: center;
  align-self: center;
}

.availability {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  text-align: center;
  align-self: center;
}

.name {
  color: #000;
  font-weight: bold;
  text-align: left;
  align-self: center;
}

.nameRu {
  text-align: left;
  align-self: center;
}

.nameUa {
  text-align: left;
  align-self: center;
}

@media screen and (max-width: 599px) {
  .item {
    font-size: 14px;
  }
}

@media screen and (max-width: 549px) {
  .item {
    font-size: 14px;
  }
}

@media screen and (max-width: 399px) {
  .item {
    font-size: 12px;
  }
}