.intro {
  width: 100%;
  position: relative;
  background: url(../../img/bg.jpg) no-repeat top center;
  /*background: url(../../img/bg_red.jpg) no-repeat top center;*/
  background-size: cover;
  height: auto;
  filter: blur(3rem);
  padding: 50% 0 0 0;
  margin-bottom: -50%;
}

@media (max-width: 767px) {
  .intro {
    //padding: 0;
  }
}