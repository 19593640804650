.next {
    position: fixed;
    background: url("../../img/arrow.png");
    background-size: contain;
    right: 0;
    top: 55%;
    bottom: 0;
    margin-right: 1em;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    z-index: 1;
}