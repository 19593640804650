.panel {
  margin: 7px 7px 0 7px;
  padding: 7px;
  font-family: sans-serif;
  @media (max-width: 767px) {
    display: none;
    margin: 7px 14px;
  }
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  width: 500px;
  min-width: 160px;
  padding: 0;
  margin: -12px 0 0 6px;
  font-size: 14px;
  list-style: none;
  color: #666;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow-y: scroll;
  max-height: 280px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
@media screen and (max-width: 890px) {
  .panel {
    width: 150px;
  }
}
@media screen and (max-width: 767px) {
  .navbar-nav .dropdown a {
    color: #666 !important;
  }
  .dropdown {
    width: auto;
  }
}
.dropdown.out {
  left: 99999px;
}
li {
  color: #444;
}
li:hover {
  color: #36720e;
}
.dropdown li {
  cursor: pointer;
  padding: 3px 20px;
  clear: both;
  word-break: break-word;
}
.dropdown li:nth-child(odd) {
  cursor: pointer;
  background-color: #a4c11da8;
}
.open > a:visited {
  background-color: #fff;
}
