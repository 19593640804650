.languageSwitcher {
  display: flex;
  align-self: center;
  margin-left: 10px;
  border: 1px solid #283601;
  height: 25px;
  width: 50px;
  @media (max-width: 767px) {
    display: inline-block;
    width: auto;
    position: fixed;
    right: 74px;
    top: 12px;
  }
}