.navbarEch {
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    display: none !important;
  }
}

.menu {
  .link {
    a {
      width: auto;
      height: auto;
    }

    .name {
      color: #000;
      @media (max-width: 767px) {
        color: #fff;
      }
    }
  }
}

