.item {
    list-style-type: none;
    text-align: center;
    //display: grid;
    //grid-template-rows: 0.1fr;
    span {
        width: 100%;
    }
}
.img {
    max-width: 98%;
    max-height: 98%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}
.wrapper {
    margin: auto;
    max-width: 98%;
    height: 200px;
}

.ech-li-item {
    color: var(--q00000);
}

.ech-li-translated {
    color: var(--q5E8E3E);
}
