.filter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 25%);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 33%);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 50%);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 100%);
  }
}

.tab {
  background-color: #36720E;
  font-family: "Lato", sans-serif !important;
  line-height: 1.6em;

  span {
    @media (max-width: 767px) {
      font-size: 0.7rem !important;
    }
    @media (max-width: 480px) {
      font-size: 0.5rem !important;
    }
  }
}

.button {
  cursor: pointer;
  background-color: #36720E;
  font-family: "Lato", sans-serif !important;
  line-height: 1.6em;
}

.white {
  color: var(--ffffff);
}

.buttonActive {
  background-color: var(--q8da356);
  font-family: "Lato", sans-serif !important;
}

.button:hover {
  background-color: #7EA565;
}

.button:active {
  background-color: #a4c11da8;
}

.button {
  background-color: #36720E;
}

//@media screen and (max-width: 991px){
//    .button {
//        font-size: 14px;
//    }
//}
//@media screen and (max-width: 799px){
//    .button {
//        font-size: 12px;
//    }
//}
//@media screen and (max-width: 549px){
//    .button {
//        font-size: 11px;
//    }
//}
//@media screen and (max-width: 479px){
//    .button {
//        font-size: 10px;
//    }
//}
//@media screen and (max-width: 449px){
//    .filter {
//        grid-template-rows: 1fr;
//        grid-template-columns: repeat(2, 2fr);
//    }
//}

