.scroll {
  margin: 0 0 30px 0;
  z-index: 1;

  .marginbot30 {
    margin-bottom: 30px !important;
  }

  .circle {
    cursor: pointer;
    border: 2px solid #fff;
    color: #fff;
    width: 70px;
    height: 70px;
    margin-top: 15px;
    padding: 3px 15px;
    border-radius: 50%;
    font-size: 40px;
    background: 0 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
  }

  .circle:hover,
  .circle:focus {
    outline: 0;
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }

  .circle i.animated {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
  }
  .circle:hover i.animated {
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@media (max-width: 480px) {
  .navbar-custom .nav.navbar-nav {
    background-color: rgba(255, 255, 255, .4);
  }
  .navbar.navbar-custom.navbar-fixed-top {
    margin-bottom: 30px;
  }
  .tp-banner-container {
    padding-top: 40px;
  }
  .scroll a.btn-circle {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    padding: 7px 0;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 20px;
  }
}
