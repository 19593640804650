.brand {
  text-shadow: -1px 1px 1px #000;
  line-height: 1;
}

.mainText {
  font-family: "Lato",sans-serif;
  line-height: 1.6em;
}

.justifyText {
  text-align: justify;
}

.centeredText {
  text-align: center;
}
