.container {
  margin-right: auto;
  margin-left: auto;
  width: -webkit-fill-available;
}

.container:before,
.container:after {
  display: table;
  content: " ";
}

.container:after {
  clear: both;
}

.homeSection {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 549px) {
    padding-top: 200px;
  }
}

.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.rowGrid {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  @media (max-width: 1200px) {
      grid-template-columns: repeat(4, 25%);
  }
  @media (max-width: 900px) {
      grid-template-columns: repeat(3, 33%);
  }
  @media (max-width: 767px) {
      grid-template-columns: repeat(2, 50%);
  }
  @media (max-width: 480px) {
      grid-template-columns: repeat(1, 100%);
  }
}

.services {
  width: 25%;
}

.boxedGrey {
  background: #f9f9f9;
  margin: 20px;
}

.contact {
  display: grid;
  grid-template-columns: 0 20% 80%;
}

.googleMap {
  width: 100%;
  height: 480px;
  border: 0;
}

.social {
  margin-left: 0;
  padding-left: 0;
  margin-top: 10px;
}

.social li {
  display: inline-block;
  margin: 0 !important;
  padding: 0 5px 0 0;
}

.social a {
  margin: 0;
  padding: 0;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #3bbec0;
  color: #fff;
  -webkit-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
}
.social a i {
  text-align: center;
  margin: 0;
  padding: 0;
}
.facebook a {
  background: #3873ae;
}

.twitter a {
  background: #62c6f8;
}

.instagram a {
  background: #D32681;
}

.yt a {
  background: #d74980;
}

.deviantart a {
  background: #8da356;
}

.google a {
  background: #000;
}

.vimeo a {
  background: #51a6d3;
}

.facebook a:hover {
  background: #4893ce;
}

.twitter a:hover {
  background: #82e6ff;
}

.instagram a:hover {
  background: #f769a0;
}

.yt a:hover {
  background: #f769a0;
}

.deviantart a:hover {
  background: #adc376;
}

.google a:hover {
  background: #333;
}

.vimeo a:hover {
  background: #71c6f3;
}
