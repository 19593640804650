.mobileOpen {
  @media (max-width: 767px) {
    display: grid;
  }
}

.mobileClosed {
  @media (max-width: 767px) {
    display: none;
  }
}

.open > ul {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown {
  position: relative;
  color: #444;
  margin-left: 7px;
  @media (max-width: 767px) {
    margin: 0;
    color: #fff;
    position: initial;
  }
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
}

.toggle:focus {
  outline: 0;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  @media (max-width: 767px) {
    display: grid;
    width: 100%;
    border-top: 1px solid #c3c3c3;
    border-radius: 0;
    padding: 0;
    background-color: #36720e;
    box-shadow: none;
    -webkit-box-shadow: none;
    text-align: end;
  }
}

.menu.pull-right {
  right: 0;
  left: auto;
}

.menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
  @media (max-width: 767px) {
    color: #fff;
  }
}

.menu > li > a:hover,
.menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.menu > .active > a,
.menu > .active > a:hover,
.menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #a4c11da8;
  outline: 0;
}

.menu > .disabled > a,
.menu > .disabled > a:hover,
.menu > .disabled > a:focus {
  color: #999;
}

.menu > .disabled > a:hover,
.menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
