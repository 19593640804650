.errorIndicator {
  right: 0;
  left: 0;
  display: flex;
  margin: 2vw;
  padding: 2vw;
  font-size: 3vw;
  line-height: 3vw;
  border: 1px solid tomato;
}
