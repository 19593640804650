.image {
  display: flex;
  left: 0;
  right: 0;
  margin: auto auto 12px auto;
  max-height: 70%;
  max-width: 100%;
}
.circle {
  border-radius: 50%;
}