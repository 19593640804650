.link {
  background-color: var(--f5f5f5);
  display: flex;
  flex-direction: column;
  min-height: 28vw;
  img {
    max-height: 17vw;
  }
}

.top {
  height: 10%;
  text-transform: uppercase;
  margin: 10%;
}

.middle {
  height: 30%;
  margin-left: 10%;
  margin-right: 10%;
}

.bottom {
  max-width: 90%;
}

.bottomImage {
  bottom: 0;
  position: relative;
}

.tile {
  position: relative;
  justify-content: space-between;
}