.home {
  section:first-child {
    margin-bottom: 0;
    filter: none;
  }
  .slogan {
    position: absolute;
    text-align: right;
    bottom: 5%;
    right: 5%;
    text-shadow: -1px 1px 1px #000;
    color: #666;
  }
  @media (min-width: 1200px) {
    .slogan {
      width: 25%;
    }
  }
  @media (max-width: 767px) {
    .slogan {
      font-size: 16px;
      width: 50%;
      color: #fff;
      float: right;
    }
  }
  @media (max-width: 600px) {
    .slogan {
      font-size: 14px;
      width: 50%;
      color: #fff;
      float: right;
    }
  }
}

.green {
  color: var(--q36720E);
}

.long {
  word-break: break-all;
}