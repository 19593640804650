.main {
  width: 100%;
  min-height: calc(100vh - 242px);
}

.mobileMenuIsOpened {
  @media (max-width: 767px) {
    margin-top: 310px;
  }
}
